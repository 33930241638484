module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145178937-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"description","store":true,"attributes":{"boost":5}},{"name":"content"},{"name":"url","store":true},{"name":"date","store":true},{"name":"people"},{"name":"tests"},{"name":"facilities"},{"name":"disciplines"},{"name":"instructions","attributes":{"boost":10}},{"name":"testingguides","attributes":{"boost":10}},{"name":"antibioticprofiles","attributes":{"boost":10}},{"name":"referenceranges","attributes":{"boost":10}},{"name":"forms","attributes":{"boost":10}},{"name":"y2025","attributes":{"boost":15}},{"name":"y2024","attributes":{"boost":15}},{"name":"y2023","attributes":{"boost":15}},{"name":"y2022","attributes":{"boost":15}},{"name":"y2021","attributes":{"boost":15}},{"name":"y2020","attributes":{"boost":15}},{"name":"y2019","attributes":{"boost":15}},{"name":"y2018","attributes":{"boost":15}},{"name":"y2017","attributes":{"boost":15}},{"name":"y2016","attributes":{"boost":15}},{"name":"y2015","attributes":{"boost":15}},{"name":"y2014","attributes":{"boost":15}},{"name":"y2013","attributes":{"boost":15}},{"name":"y2012","attributes":{"boost":15}},{"name":"older","attributes":{"boost":15}}],"resolvers":{"MarkdownRemark":{}},"filename":"search_index.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
